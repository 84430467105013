<template>
  <div id="DataLakeDashboard">

    <a-button class="button" type="primary" @click="this.newDataLake()">
      New
    </a-button>

    <a-button class="button" type="primary" @click="this.refreshQuery()">
      Refresh
    </a-button>

    <a-switch v-model:checked="refresh"  @change="this.refreshAuto()"/>
    <a-spin :indicator="indicatorRefresh" :spinning="spinning"/>

    <ag-grid-vue
        id="table"
        style=" height: 700px;"
        class="ag-theme-alpine"
        :columnDefs="columns"
        :rowData="tasks"
        :getRowStyle="getRowStyle"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef">
    </ag-grid-vue>

    <a-modal
        v-model:visible="newDataLakeVisible"
        :closable=false
        title="DataLake to Google Drive"
        @ok="okNewDataLake">
      <a-form
          v-model:model="formDataLake"
          name="form_datalake"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
      >

        <a-form-item label="dataLake name" :rules="[{ required: true, message: 'Please input datalake name' }]">
          <a-input v-model:value="formDataLake['dataLakeName']" placeholder="DL_xxxxxxxx" required/>
        </a-form-item>

        <a-form-item label="load_uuid" :rules="[{ required: true, message: 'Please input load_uuid' }]">
          <a-input v-model:value="formDataLake['load_uuid']" placeholder="load_uuid" required />
        </a-form-item>

        <a-form-item label="entities">
          <a-textarea v-model:value="formDataLake['entities']" placeholder="entity1,entity2" />
        </a-form-item>

      </a-form>
    </a-modal>

  </div>

</template>

<script>

import {AgGridVue} from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { LoadingOutlined } from '@ant-design/icons-vue';
import {persistorApi, TLSEnabled} from "@/config/api";
import {message, notification} from 'ant-design-vue';
import {reactive} from "vue";
import { h } from 'vue';

function linkCellRenderer(params) {
  return `<a href="${params.value}" target="_blank">${params.value}</a>`;
}

export default {
  components: {
    AgGridVue
  },
  props: ['keycloak','isActive'],
  setup() {

  },
  data() {
    return {
      refresh: true,
      spinning: true,
      refreshTimer: null,
      indicatorRefresh: h(LoadingOutlined, {
        style: {
          fontSize: '24px',
        },
        spin: true,
      }),
      corporation: reactive({}),
      headers: {
        authorization: "Bearer " + this.keycloak.token,
      },
      gridApi: null,
      columnApi: null,
      gridOptions: {
        enableCellTextSelection: true,
        pagination: false,
        paginationAutoPageSize: false,
        getRowId: (item) => {
          return item.data.id;
        },
      },
      defaultColDef: {
        filter: true,
        wrapText: true,
        autoHeight: true,
        enableCellChangeFlash: true,
      },
      columns: [
        {
          headerName: 'Date',
          width: 200,
          field: 'created',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']}
        },
        {
          headerName: 'DataLake name',
          width: 150,
          field: 'dataLakeName',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Load_uuid',
          width: 350,
          field: 'load_uuid',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']}
        },
        {
          headerName: 'Entities',
          width: 250,
          field: 'entities',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']}
        },
        {
          headerName: 'Status',
          width: 130,
          field: 'status',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Link',
          cellRenderer: linkCellRenderer,
          width: 800,
          field: 'link',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
      ],
      tasks: [],
      newDataLakeVisible: false,
      visibleToken: false,
      uri: '',
      formDataLake: reactive({})
    }
  },
  watch: {
    isActive: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.setTimer()
        } else {
          this.cancelTimer()
        }
      },
    },
  },
  created() {
    this.refreshQuery()
    this.setTimer()
  },
  methods: {
    okNewDataLake() {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + persistorApi + "/datalake/dataLakeToDrive"
      
      if (this.formDataLake.dataLakeName === '' || this.formDataLake.dataLakeName === null || this.formDataLake.dataLakeName === undefined ) {
        message.error('datalake name is required');
        return(false)
      }

      if (this.formDataLake.load_uuid === '' || this.formDataLake.load_uuid === null || this.formDataLake.load_uuid === undefined ) {
        message.error('load_uuid is required');
        return(false)
      }

      const newDataLakeToDrive = {
        dataLakeName: this.formDataLake.dataLakeName.toUpperCase(),
        load_uuid: this.formDataLake.load_uuid,
        entities: this.formDataLake.entities && this.formDataLake.entities.trim() !== '' ? this.formDataLake.entities.split(',').map(item => item.trim()) : []
      }

      console.log(newDataLakeToDrive.entities)
      const token = "Bearer " + this.keycloak.token
      fetch(uri, {
        method: "POST",
        body: JSON.stringify(newDataLakeToDrive),
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
        .then(async response => {
          if (!response.ok) {
            const error = (await response.text() || response.statusText)
            return Promise.reject(error)
          }
          else {
            this.refreshQuery()
          }
        })
        .catch(error => {
          this.errorNotification(error)
        })
        this.newDataLakeVisible = false
    },
    newDataLake() {
      this.formDataLake.name = ""
      this.newDataLakeVisible = true
    },
    setTimer() {
      if (this.refreshTimer == null) {
        this.refreshTimer = setInterval(this.refreshQuery, 10000)
        this.spinning = true
      }
    },
    cancelTimer() {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
      this.spinning = false
    },
    refreshAuto() {
      if (this.refresh)
        this.setTimer()
      else
        this.cancelTimer()
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const defaultSortModel = [
        {colId: 'created', sort: 'asc'}
      ];
      this.gridColumnApi.applyColumnState({state: defaultSortModel});
    },
    getRowStyle: (params) => {
      switch (params.data.status) {
        case "FAIL":
          return {'color': 'red'}
        case "DONE":
          return {'color': 'green'}
        default:
          return {'color': 'blue'}
      }
    },
    refreshQuery() {
      if (!this.refresh)
        this.cancelTimer()
      const token = this.keycloak.token
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + persistorApi + "/tasks/users/" + this.keycloak.subject + "/task/data_lake_to_drive?limit=20"
      const tokenBearer = "Bearer " + token
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": tokenBearer
        }
      })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((responseJson) => {
            this.tasks = []

            responseJson.results.forEach(task =>{
              const payload = JSON.parse(task.payload)
              const msg = task.msg && task.msg.trim() !== '' ? JSON.parse(task.msg) : { "link": "" };              
              this.tasks.push({
                id: task.id,
                created: task.created,
                dataLakeName: payload.dataLakeName,
                load_uuid: payload.load_uuid,
                entities: payload.entities,
                status: task.status,                    
                link: msg.link,
              })
            })

          })
          .catch((error) => {
            console.log(error)
          })
    },
    errorNotification(msg) {
      console.log(msg)
      notification.open({
        message: 'Error',
        description: msg,
        class: 'error-class',
      });
    }
  }

}
</script>

<style>

#table {
  margin-top: 20px;
  margin-bottom: 20px;
}

.button {
  margin-right: 20px;
}

.ant-btn {
  color: #fff !important;
  border-color: #40a9ff !important;
  background: #40a9ff !important;
  margin-right: 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>