export const apiAuthorizeUrl = 'KEYCLOAK_URL_ENV'
export const apiUrl = 'REST_API_URL_ENV'
export const authEnabled = eval("AUTH_ENABLED_KEYCLOAK_ENV")
export const TLSEnabled = eval("TLS_ENABLED_ENV")
export const zeppelinApi = 'ZEPPELIN_SERVER_ENV'
export const persistorApi = 'PERSISTOR_SERVER_ENV'

// export const apiAuthorizeUrl = 'https://nep.dev.gc.axesor.com/auth/'
// export const apiUrl = 'localhost:9001'
// export const authEnabled = true
// export const TLSEnabled = false
// export const zeppelinApi = 'atlax360-zeppelin-nb-dev.gc.axesor.com'
// export const persistorApi = 'localhost:5000'

//export const apiAuthorizeUrl = 'https://nep.dev.gc.axesor.com/auth/'
//export const apiUrl = 'zepp-api-dev.atlax360.com'
//export const authEnabled = true
//export const TLSEnabled = true
//export const zeppelinApi = 'zeppelin-nb-dev.atlax360.com'
//export const persistorApi = 'localhost:9001'

//export const apiAuthorizeUrl = 'https://nep.dev.gc.axesor.com/auth/'
//export const apiUrl = 'zepp-api-dev.atlax360.com'
//export const apiUrl = 'localhost:5005'
//export const authEnabled = true
//export const TLSEnabled = false
//export const zeppelinApi = 'zeppelin-nb-dev.atlax360.com'
//export const persistorApi = 'localhost:9001'
